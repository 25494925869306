export default [
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 20,
    header: {
      sort: 'CreatedAt',
      type: 'text',
      caption: 'Started / Ended',
    },
    cell: {
      type: 'slot',
      name: 'startedEnded',
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'Pair',
    },
    cell: {
      type: 'slot',
      name: 'pair',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Payment method',
    },
    cell: {
      type: 'slot',
      name: 'paymentMethod',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Seller',
    },
    cell: {
      type: 'slot',
      name: 'seller',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Seller amount',
    },
    cell: {
      type: 'slot',
      name: 'sellerAmount',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Buyer',
    },
    cell: {
      type: 'slot',
      name: 'buyer',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Buyer amount',
    },
    cell: {
      type: 'slot',
      name: 'buyerAmount',
    },
  },
];
