import { render, staticRenderFns } from "./P2PDealsFilters.vue?vue&type=template&id=db907f32&scoped=true&"
import script from "./P2PDealsFilters.vue?vue&type=script&lang=js&"
export * from "./P2PDealsFilters.vue?vue&type=script&lang=js&"
import style0 from "./P2PDealsFilters.vue?vue&type=style&index=0&id=db907f32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db907f32",
  null
  
)

export default component.exports