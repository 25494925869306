//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import P2PDeals from '~/views/P2POffersAndDeals/P2PDeals/P2PDeals.vue';
import P2POffers from '~/views/P2POffersAndDeals/P2POffers/P2POffers.vue';
import P2PModerationWords from '~/views/P2POffersAndDeals/P2PModerationWords/P2PModerationWords.vue';

export default {
  components: {
    P2PDeals,
    P2POffers,
    P2PModerationWords,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadP2pDeals(),
      this.loadP2pOffers(),
      this.loadP2PModerationWords(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('p2pDeals', {
      loadP2pDeals: 'loadData',
    }),
    ...mapActions('p2pOffers', {
      loadP2pOffers: 'loadData',
    }),
    ...mapActions('p2pModerationWords', {
      loadP2PModerationWords: 'loadData',
    }),
  },
};
