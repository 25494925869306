var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deals"},[_c('div',{staticClass:"deals__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('GetReport',{attrs:{"type":_vm.constant.reports.REPORT_TYPE.P2P_DEALS,"command":_vm.requestData}})],1),_c('div',{staticClass:"deals__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-extra":""},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.p2p.TRADE_STATUS_LIST, row.status))+" ")]}},{key:"startedEnded",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.createdAt))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.createdAt)))]),(row.endedAt)?[_vm._v(" / "+_vm._s(_vm.$utils.getDate(row.endedAt))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.endedAt)))])]:_vm._e()],2)]}},{key:"pair",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getPair(row))+" ")]}},{key:"paymentMethod",fn:function(ref){
var row = ref.row;
return [(row.paymentMethod)?[_vm._v(" "+_vm._s(row.paymentMethod.name)+" ")]:_vm._e()]}},{key:"seller",fn:function(ref){
var row = ref.row;
return [_c('User',{class:{'bold': _vm.isAuthorOfDeal(row, row.cryptoSeller.username)},attrs:{"id":row.cryptoSeller.id,"username":row.cryptoSeller.username}})]}},{key:"buyer",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('User',{class:{'bold': _vm.isAuthorOfDeal(row, row.cryptoBuyer.username)},attrs:{"id":row.cryptoBuyer.id,"username":row.cryptoBuyer.username}}),(row.dateArbitratorEntered)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-face-agent ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Arbitrator entered'))+" "+_vm._s(_vm.$utils.getFullDate(row.dateArbitratorEntered)))])]):_vm._e()],1)]}},{key:"sellerAmount",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.cryptoSellerAmount, row.cryptoSellerCurrency.title))+" "+_vm._s(row.cryptoSellerCurrency.title)+" ")]}},{key:"buyerAmount",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.cryptoBuyerAmount, row.cryptoBuyerCurrency.title))+" "+_vm._s(row.cryptoBuyerCurrency.title)+" ")]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }