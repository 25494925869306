//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './P2PModerationWordsFilters.vue';
import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  components: {
    Filters,
  },

  data() {
    return {
      tableConfig,
      loader: false,
      editingWords: {
        loader: false,
        modal: false,
        isCreate: false,
        isDisabledButton: false,
        data: {
          id: null,
          isEnabled: false,
          groupName: '',
          words: '',
        },
      },
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('p2pModerationWords', ['data']),
    ...mapGetters('p2pModerationWords', ['requestData']),

    isDisabledButtonState() {
      const { groupName, words } = this.editingWords.data;
      return words.length === 0 || groupName.length === 0;
    },

    enabledOfferState() {
      return this.editingWords.data.isEnabled ? 'Enabled Offer' : 'Disabled Offer';
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('p2pModerationWords', {
      loadDataAction: 'loadData',
      deleteWordsAction: 'deleteWords',
      editWordsAction: 'editWords',
      createWordsAction: 'createWords',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    deleteWords(id) {
      this.confirmAction({
        title: 'Are you sure you want to delete words group?',
        hasPrompt: false,
        promptRequired: false,
        callback: async () => {
          this.setGeneralProgress(true);
          await this.deleteWordsAction({
            id,
          });
          this.loader = true;
          await this.loadDataAction({});
          this.loader = false;
          this.setGeneralProgress(false);
          this.setSuccessNotification('Words group successfully deleted');
        },
      });
    },

    async editModerationWord() {
      const {
        isEnabled, words, groupName, id,
      } = this.editingWords.data;
      try {
        const wordsFormat = words.split(',').map((word) => word.trim()).filter(Boolean);
        this.setGeneralProgress(true);
        await this.editWordsAction({
          stringFormat: {
            id,
            isEnabled,
            groupName,
          },
          words: wordsFormat,
        });
        this.loader = true;
        await this.loadDataAction({});
        this.loader = false;
        this.setGeneralProgress(false);
        this.editingWords.modal = false;
        this.setSuccessNotification('Words group successfully updated');
      } catch (error) {
        this.setErrorNotification(error);
      }
    },

    async createModerationWord() {
      const {
        isEnabled, words, groupName,
      } = this.editingWords.data;
      try {
        const wordsFormat = words.split(',').map((word) => word.trim()).filter(Boolean);
        this.setGeneralProgress(true);
        await this.createWordsAction({
          isEnabled,
          groupName,
          words: wordsFormat,
        });
        this.loader = true;
        await this.loadDataAction({});
        this.loader = false;
        this.setGeneralProgress(false);
        this.editingWords.modal = false;
        this.setSuccessNotification('Words group successfully added');
      } catch (error) {
        this.setErrorNotification(error);
      }
    },

    editModerationWordState(data) {
      const {
        isEnabled, words, groupName, id,
      } = data;
      this.editingWords.modal = true;
      this.editingWords.isCreate = false;
      this.editingWords.data.isEnabled = isEnabled;
      this.editingWords.data.words = words;
      this.editingWords.data.id = id;
      this.editingWords.data.groupName = groupName;
    },

    addModerationWordState() {
      this.editingWords.modal = true;
      this.editingWords.isCreate = true;
      this.editingWords.data.isEnabled = true;
      this.editingWords.data.words = '';
      this.editingWords.data.groupName = '';
    },
  },
};
