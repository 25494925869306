export default [
  {
    caption: 'Enable selected offers',
    action: 'enableOffers',
  },
  {
    caption: 'Disable selected offers',
    action: 'disableOffers',
  },
  {
    caption: 'Delete selected offers',
    action: 'deleteOffers',
  },
];
