//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        SearchString: null,
        CreatedAtLower: null,
        CreatedAtUpper: null,
        BaseCurrencyId: null,
        QuoteCurrencyId: null,
        Statuses: [],
        AmountLower: null,
        AmountUpper: null,
        PriceLower: null,
        PriceUpper: null,
        isCrossTrade: null,
      },
      constant,
    };
  },

  computed: {
    ...mapState('p2pDeals', ['filterLists']),
    ...mapState('p2p', ['p2pCurrencies']),

    cryptoCurrencies() {
      const { p2pCurrencies } = this;
      return p2pCurrencies.filter((e) => e.type === constant.p2p.CURRENCY_TYPE.CRYPTO);
    },

    fiatCurrencies() {
      const { p2pCurrencies } = this;
      return p2pCurrencies.filter((e) => e.type === constant.p2p.CURRENCY_TYPE.FIAT);
    },

    fiatAndCryptoCurrencies() {
      const { fiatCurrencies, cryptoCurrencies } = this;
      return [...fiatCurrencies, ...cryptoCurrencies];
    },

    crossTradeItems() {
      return [
        {
          id: true,
          caption: 'Cross trades only',
        },
        {
          id: false,
          caption: 'Direct trades only',
        },
      ];
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    const {
      DealsSearchString,
    } = this.$route.query;
    if (DealsSearchString) {
      this.filters = {
        ...this.filters,
        SearchString: DealsSearchString || '',
      };
      this.setFilters(this.filters);
      this.$emit('update');
      this.$router.replace({ query: {} });
      this.$refs.p2pDealsBlock.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      this.setFilters({});
    }
  },

  methods: {
    ...mapActions('p2pDeals', ['setFilters']),
  },
};
