//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    data: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      showingHistory: {
        modal: false,
        current: 0,
      },
      constant,
    };
  },

  computed: {
    ...mapState('p2pOffers', ['history']),

    restrictAmounts() {
      const { restrictAmounts } = this.data;

      if (restrictAmounts.length === 0) return '–';
      return restrictAmounts.join(', ');
    },

    paymentMethods() {
      const { paymentMethods } = this.data;
      return paymentMethods
        .map((e) => e.name)
        .join(', ');
    },

    preparedHistory() {
      const { history } = this;
      let lastStatusOffer = false;
      const preparedHistory = history.map((e) => {
        let oldValues = {};
        let newValues = {};
        const values = {};
        try {
          oldValues = JSON.parse(e.oldValues) || {};
          newValues = JSON.parse(e.newValues) || {};
          if (newValues.IsEnabled !== undefined) {
            lastStatusOffer = newValues.IsEnabled;
          }
        } catch (error) {
          console.error(error);
        }
        Object.keys(oldValues).forEach((v) => {
          values[v] = null;
        });
        Object.keys(newValues).forEach((v) => {
          values[v] = null;
        });
        return {
          ...e,
          oldValues,
          newValues,
          values: Object.keys(values),
          offerStatus: lastStatusOffer,
        };
      });
      return preparedHistory;
    },

    historyTableConfig() {
      return [
        {
          width: 20,
          header: { type: 'text', caption: 'Key' },
          cell: { type: 'slot', name: 'key' },
        },
        {
          width: 40,
          header: { type: 'text', caption: 'Old value' },
          cell: { type: 'slot', name: 'oldValue' },
        },
        {
          width: 40,
          header: { type: 'text', caption: 'New value' },
          cell: { type: 'slot', name: 'newValue' },
        },
      ];
    },

    historyTableData() {
      const { preparedHistory } = this;
      const { current } = this.showingHistory;
      const currentItem = preparedHistory[current];
      if (!currentItem) return [];

      return currentItem.values.map((e) => ({
        key: e,
        oldValue: currentItem.oldValues[e],
        newValue: currentItem.newValues[e],
      }));
    },

    currentInitiator() {
      const { preparedHistory } = this;
      const { current } = this.showingHistory;
      const currentItem = preparedHistory[current];
      if (!currentItem) return '';
      return currentItem.initiator;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('p2pOffers', {
      loadHistoryAction: 'loadHistory',
    }),

    deleteOffer() {
      const { id } = this.data;
      this.$emit('delete', { id });
    },

    disableOffer() {
      this.$emit('disable', this.data);
    },

    enableOffer() {
      this.$emit('enable', this.data);
    },

    editTerms() {
      this.$emit('editTerms', this.data);
    },

    async showHistory() {
      const { id } = this.data;
      this.setGeneralProgress(true);
      await this.loadHistoryAction({ id });
      this.setGeneralProgress(false);
      this.showingHistory.modal = true;
      this.showingHistory.current = 0;
    },
  },
};
