//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './P2PDealsFilters.vue';
import User from '~/components/User.vue';
import Extra from './P2PDealsExtra.vue';
import GetReport from '~/components/GetReport.vue';

import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  components: {
    Filters,
    User,
    Extra,
    GetReport,
  },

  props: { userId: { type: String, default: '' } },

  data() {
    return {
      tableConfig,
      loader: false,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('currencies', ['toCurrencyFormat']),

    ...mapState('p2pDeals', ['page', 'pages', 'data', 'sort']),
    ...mapGetters('p2pDeals', ['requestData']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('p2pDeals', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData({
        ...(this.userId && { userId: this.userId }),
      });
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData({
        ...(this.userId && { userId: this.userId }),
      });
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData({
        extendedRequestData,
        ...(this.userId && { userId: this.userId }),
      });
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getPair(row) {
      const { cryptoSellerCurrency, cryptoBuyerCurrency } = row;
      if (cryptoSellerCurrency && cryptoBuyerCurrency) {
        return `${cryptoSellerCurrency.title} / ${cryptoBuyerCurrency.title}`;
      }
      return '';
    },

    isAuthorOfDeal(row, username) {
      const { advertisement } = row;
      return advertisement.author.username === username;
    },
  },
};
