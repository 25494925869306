//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        SearchString: null,
        BaseCurrencyId: [],
        QuoteCurrencyId: [],
        MinimumLimit: null,
        MaximumLimit: null,
        isEnabled: null,
        isBlocked: null,
        isHidden: null,
        isDeleted: null,
      },
      Statuses: [],
      constant,
    };
  },

  computed: {
    ...mapState('p2pOffers', ['filterLists']),
    ...mapState('p2p', ['p2pCurrencies']),

    cryptoCurrencies() {
      const { p2pCurrencies } = this;
      return p2pCurrencies.filter((e) => e.type === constant.p2p.CURRENCY_TYPE.CRYPTO);
    },

    fiatCurrencies() {
      const { p2pCurrencies } = this;
      return p2pCurrencies.filter((e) => e.type === constant.p2p.CURRENCY_TYPE.FIAT);
    },

    fiatAndCryptoCurrencies() {
      const { fiatCurrencies, cryptoCurrencies } = this;
      return [...fiatCurrencies, ...cryptoCurrencies];
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
    Statuses(statusesArray) {
      this.updateStatuses(statusesArray);
    },
  },

  mounted() {
    const {
      OffersSearchString,
    } = this.$route.query;
    if (OffersSearchString) {
      this.filters = {
        ...this.filters,
        SearchString: OffersSearchString || '',
      };
      this.setFilters(this.filters);
      this.$emit('update');
      this.$router.replace({ query: {} });
      this.$refs.p2pOffersBlock.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      this.setFilters({});
    }
  },

  methods: {
    ...mapActions('p2pOffers', ['setFilters']),

    updateStatuses(statusesModel) {
      const statusesList = ['isBlocked', 'isEnabled', 'isHidden', 'isDeleted'];
      statusesList.forEach((status) => {
        if (statusesModel.includes(status)) {
          this.filters[status] = true;
        } else {
          this.filters[status] = null;
        }
      });
    },
  },
};
