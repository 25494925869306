export default [
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Group name',
    },
    cell: {
      type: 'text',
      value: (e) => e.groupName,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Words',
    },
    cell: {
      type: 'slot',
      name: 'words',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
];
